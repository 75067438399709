import React, { useState, useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { getUserDetails } from "../../src/services/api/api.service";
import Header from "../component/common/header";
import Navbar from "../component/common/navbar";
import { Helmet } from "react-helmet";

// import BottomMenu from "../common/BottomMenubar";

// import NewOpportunity from "../common/newOpportunity";

import { useSelector } from "react-redux";

const PrivateRoute = ({ ...rest }) => {
  const getToken = useSelector((state) => state.userToken.authenticate[0]);
  // const getToken = useSelector((state) => state.VerifyUser.verifyUserRes[0].redirect_options);
  // const getToken2 = useSelector((state) => state.getUserData.getData[0].is_user_active);

  // console.log("getToken", getToken);
  console.warn("rought", rest);

  if (rest.path === "/investor-opportunities/") {
    return (
      <>
        {/* <NewOpportunity /> */}
        <Header />
        <Navbar />
        {rest.element}
        {/* <BottomMenu /> */}
      </>
    );
  } else if (
    rest.path === "/insolvency-case-pool/:id/:poolStatus/:minimum_contribution "
  ) {
    return (
      <>
        <Helmet>
          <title>{`${rest?.title} - AllWin Technologies`}</title>
        </Helmet>
        {/* <NewOpportunity /> */}
        <Header />
        <Navbar />
        {rest.element}
        {/* <BottomMenu /> */}
      </>
    );
  } else if (
    rest.path === "/case-pool/:id/:poolStatus/:minimum_contribution "
  ) {
    return (
      <>
        {/* <NewOpportunity >/ */}
        <Header />
        <Navbar />
        {rest.element}
        {/* <BottomMenu /> */}
      </>
    );
  } else if (getToken) {
    return (
      <>
        {/* <NewOpportunity /> */}
        <Header />
        <Navbar />
        {rest.element}
        {/* <BottomMenu /> */}
      </>
    );
  } else {
    return <Navigate to="/investor-login-n1-int/" />;
  }
};

export default PrivateRoute;
