import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { ENQUIRY } from "../../Redux/Actions/Actions";

export default function EnquiryForm({setEnquiryPopup}) {
  const form = useRef();
  const Dispatch = useDispatch();
    const handelAction = () => {
        setEnquiryPopup(false)
    // Dispatch(ENQUIRY("false"));
  };    
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_14ycjpn",
        "template_7blxo8p",
        form.current,
        "JoYg69MXyJU_Q2o3h"
      )
      .then(
        (result) => {
          toast.success("Request successfully submitted");
          e.target.reset();
              console.log(result.text);
        setEnquiryPopup(false)
              
        },
        (error) => {
          console.log(error.text);
          toast.error("Something went wrong");
        }
      );
  };

  return (
    <div className="EnquiryForm">
      <div className="row">
        <div className="actionClose">
            <span></span>
          <h5 className="contact_us_Head">Contact us</h5>
          <span onClick={handelAction}>
            <AiOutlineCloseCircle />
          </span>
        </div>
        <div>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <div>
                <input
                  type="text"
                  name="form_name"
                  placeholder="Full Name"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  name="form_email"
                  placeholder="Email Address"
                  required
                />
              </div>
              <div>
                <input
                  type="tel"
                  name="form_number"
                  placeholder="Enter Mobile Number"
                //   pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  required
                />
              </div>
              <div>
                <textarea
                  name="form_message"
                  placeholder="How can we help?"
                  required
                ></textarea>
              </div>
              <div className="action-button">
                <button className="btn-primary-line" value="Send">
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
