import "bootstrap/dist/css/bootstrap.min.css";
import "./style/blue.css";
import "./style/bootstrap.min.css";
// import "./style/font-awesome.css";
import "./style/magnific-popup.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MainRoutes from "./Routes/index";
import React, { useEffect, useState } from "react";
import EnquiryForm from "./component/common/enquiryForm";
import EnquiryButton from "./component/common/enquiryButton";
import Loader from "./component/common/loader";

const TRACKING_ID = "G-LT85T86GFX";
const CLARITY_TRACKING_ID = "jhkvkyrv88";

function App() {
  const [loader, setLoader] = useState(false);
  const [enquiryPopup, setEnquiryPopup] = useState(false);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, [window.location.pathname]);

  useEffect(() => {
    // Your Clarity tracking code
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", CLARITY_TRACKING_ID);
  }, []);

  useEffect(() => {
    // Your Google Analytics tracking code
    // Replace 'G-82FWW4G1HY' with your own Google Analytics property ID
    (function () {
      var script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = function () {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", TRACKING_ID);
      };
    })();
  }, []);

  return (
    <>
      {loader && <Loader />}
      {enquiryPopup && <EnquiryForm setEnquiryPopup={setEnquiryPopup} />}
      {!enquiryPopup && <EnquiryButton setEnquiryPopup={setEnquiryPopup} />}
      <ToastContainer position="top-right" />
      <MainRoutes />
    </>
  );
}
export default App;
