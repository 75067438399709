import React from 'react'

export default function Loader() {
  return (
    <div className="loader-wrapper">
    <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
    </div>
</div>
  )
}
