const INIT_STATE0 = {
  authenticate: ["21312312"],
};
export const userToken = (state = INIT_STATE0, action) => {
  switch (action.type) {
    case "TOKEN":
      return {
        authenticate: [action.payload],
      };
    default:
      return state;
  }
};
const INIT_STATE1 = {
  status: [{ status1: true }],
};
export const EnquiryPopup = (state = INIT_STATE1, action) => {
  switch (action.type) {
    case "ENQUIRY":
      return {
        status: [action.state],
      };
    default:
      return state;
  }
};
