import React from 'react'
import Conversation from "../../assets/icons/conversation_2.png"

export default function EnquiryButton({setEnquiryPopup}) {
  return (
    <div className='EnquiryButton' onClick={()=>setEnquiryPopup(true)}>
    <h5 className='bigScreenEnquiry'>Quick Enquiry</h5>
    <img src={Conversation} alt="Quick Enquiry" className='mobileScreenEnquiry' style={{width:"30px"}}/>
    </div>
  )
}
