import React from "react";

//Common
const PageNotFound = React.lazy(() => import("../error/PageNotFound"));
const ForbiddenAccess = React.lazy(() => import("../error/ForbiddenAccess"));

// login-SignUp
const Login = React.lazy(() => import("../pages/login"));
const SignUp = React.lazy(() => import("../pages/sign-up"));
const RecoverAccount = React.lazy(() => import("../pages/forgot-password"));

// public-Pages
const Home = React.lazy(() => import("../pages/dashboard/home"));
const OurServices = React.lazy(() => import("../pages/dashboard/our-services"));
const OurWorks = React.lazy(() => import("../pages/dashboard/our-works"));
const Blogs = React.lazy(() => import("../pages/dashboard/blogs"));
const BlogsDetails = React.lazy(() =>
  import("../component/dashboard/blogs/blogs_details")
);
const OurTeam = React.lazy(() => import("../pages/dashboard/our-works"));
const TeamDetails = React.lazy(() =>
  import("../component/dashboard/our-team/team-details")
);
const About = React.lazy(() => import("../pages/dashboard/about-us"));
const Contact = React.lazy(() => import("../pages/dashboard/contact-us"));
const ConnectUs = React.lazy(() => import("../pages/dashboard/contact-us"));
const Career = React.lazy(() => import("../pages/dashboard/career"));
const PrivacyPolicy = React.lazy(() =>
  import("../pages/dashboard/privacy-policy")
);
const TermsServices = React.lazy(() =>
  import("../pages/dashboard/terms-service")
);

const FAQs = React.lazy(() => import("../pages/dashboard/faqs"));

const configureRoutes = () => {
  const routes = [
    // common pages
    {
      element: <Home />,
      exact: true,
      path: "/",
      title: "Home",
      type: "public",
    },
    {
      element: <Login />,
      exact: true,
      path: "/login",
      title: "login page",
      type: "login",
    },
    {
      element: <SignUp />,
      exact: true,
      path: "/register",
      title: "SignUp page",
      type: "login",
    },

    {
      element: <RecoverAccount />,
      exact: true,
      path: "/password-reset",
      title: "Account-Recover page",
      type: "login",
    },
    {
      element: <ForbiddenAccess />,
      exact: true,
      path: "/forbidden-access",
      title: "403 Forbidden Access",
      type: "error",
    },
    {
      element: <PageNotFound />,
      exact: true,
      path: "*",
      title: "404 Not Found",
      type: "public",
    },

    // public pages
    {
      element: <OurServices />,
      exact: true,
      path: "/services",
      title: "Our Services",
      type: "public",
    },
    {
      element: <OurWorks />,
      exact: true,
      path: "/our-works",
      title: "Our Works",
      type: "public",
    },
    {
      element: <OurTeam />,
      exact: true,
      path: "/our-team",
      title: "Our team",
      type: "public",
    },
    {
      element: <TeamDetails />,
      exact: true,
      path: "/team-details/:id",
      title: "Team Details ",
      type: "public",
    },

    {
      element: <About />,
      exact: true,
      path: "/about-us",
      title: "About Us",
      type: "public",
    },
    {
      element: <ConnectUs />,
      exact: true,
      path: "/connect-us",
      title: "Contact Us",
      type: "public",
    },
    -{
      element: <Contact />,
      exact: true,
      path: "/contact-us",
      title: "Contact Us",
      type: "public",
    },

    {
      element: <Blogs />,
      exact: true,
      path: "/blogs",
      title: "Blogs",
      type: "public",
    },
    {
      element: <BlogsDetails />,
      exact: true,
      path: "/blog-detail/:id",
      title: "Blog Detail",
      type: "public",
    },

    {
      element: <Career />,
      exact: true,
      path: "/career",
      title: "Career",
      type: "public",
    },
    {
      element: <PrivacyPolicy />,
      exact: true,
      path: "/privacy_policy",
      title: "PrivacyPolicy",
      type: "public",
    },
    {
      element: <TermsServices />,
      exact: true,
      path: "/terms-of-service/",
      title: "Terms Services",
      type: "public",
    },
    {
      element: <FAQs />,
      exact: true,
      path: "/investor-faqs",
      title: "FAQs",
      type: "private",
    },
  ];

  return routes;
};

export default configureRoutes;
