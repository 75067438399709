import React, { useEffect } from "react";
import Header from "../component/common/header";
import Navbar from "../component/common/navbar";
import Footer from "../component/common/footer";
import WhatsappConnect from "../component/common/whatsappConnect";
import { Helmet } from "react-helmet";

// import NewOpportunity from "../common/newOpportunity";

const PublicRoute = ({ ...rest }) => {
  console.warn("rought", rest);

  return (
    <>
      <Helmet>
        <title>{`${rest?.title} - AllWin Technologies`}</title>
      </Helmet>
      {/* <NewOpportunity /> */}
      <WhatsappConnect />
      <Header />
      <Navbar />
      {rest.element}
      <Footer />
    </>
  );
};
export default PublicRoute;
