import React from 'react'
import { Link } from 'react-router-dom'
import WhatsappIcon from '../../assets/icons/whatsapp.png'

export default function WhatsappConnect() {
  return (
    <div className='whatsapp_contact'>
      <a target='blank' href="https://wa.me/918368535571/?text=Hi%20AllWin%20Technologies%20Team,%20I%20have%20a%20question%20and%20would%20like%20to%20get%20in%20touch%20with%20you.">
       <img src={WhatsappIcon} alt="whatsapp_icon" />
      </a>
    </div>
  )
}
