import React from "react";
// import LoginNav from "../common/loginNav";
// import Footer from '../common/footer';

const LoginRoute = ({ ...rest }) => {
  return (
    <>
      {/* <LoginNav /> */}
      {rest.element}
      {/* <Footer /> */}
    </>
  );
};
export default LoginRoute;
