import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SuspenseLoader from "./component/common/suspenseLoader";
import store, { persistor } from "./store";
import { Provider } from "react-redux";
import ErrorBoundary from "./error/Error-Boundary";
import { PersistGate } from "redux-persist/integration/react";

let NODE_ENV = "production";
// if (NODE_ENV !== "development") console.log = () => {};

let prevLocation = {};

if (process.env.REACT_APP_ENV === "prod") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={<SuspenseLoader />} persistor={persistor}>
      <ErrorBoundary>
        <React.Suspense fallback={<SuspenseLoader />}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </React.Suspense>
      </ErrorBoundary>
    </PersistGate>
  </Provider>
);

// reportWebVitals();
