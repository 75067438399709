import React, { useState } from "react";
import Logo from "../../assets/logos/logo.png";

export default function Navbar() {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <header>
      <nav className="navbar">
        <a href="/" className="logo">
          <img src={Logo} alt="All-Win-Tech" width={20} />
        </a>
        <ul className={`nav-menu ${menuVisible ? "active" : ""}`}>
          <li className="nav-item">
            <a href="/" className="nav-link" onClick={closeMenu}>
              HOME
            </a>
          </li>
          <li className="nav-item">
            <a href="/services" className="nav-link" onClick={closeMenu}>
              SERVICES
            </a>
          </li>
          <li className="nav-item">
            <a href="/about-us" className="nav-link" onClick={closeMenu}>
              ABOUT US
            </a>
          </li>
          <li className="nav-item">
            <a href="/blogs" className="nav-link" onClick={closeMenu}>
              BLOGS
            </a>
          </li>
          <li className="nav-item">
            <a href="/connect-us" className="nav-link" onClick={closeMenu}>
              CONTACT US
            </a>
          </li>
        </ul>
        <div className="hamburger" onClick={toggleMenu}>
          <span className={`bar ${menuVisible ? "active" : ""}`}></span>
          <span className={`bar ${menuVisible ? "active" : ""}`}></span>
          <span className={`bar ${menuVisible ? "active" : ""}`}></span>
        </div>
      </nav>
    </header>
  );
}
