import React from "react";
import {
  BiPhoneCall,
  BiLogoTwitter,
  BiLogoLinkedin,
  BiLogoFacebook,
  BiLogoInstagramAlt,
} from "react-icons/bi";
import Logo from "../../assets/logos/logo.png";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <img src={Logo} alt="" style={{ width: "20%",objectFit:"contain",height:"100px" }} />
            <div className="text" style={{ color: "#11eb90" }}>
              Empowering Tomorrow's Tech Solutions: Innovate, Integrate,
              Inspire.
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-6">
            <h5>Helpful Links</h5>
            <ul className="footer-nav">
              <li>
                <a href="/">
                  {/* <i className="fa fa-angle-right"></i> */}
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a href="/about-us">
                  {/* <i className="fa fa-angle-right"></i> */}
                  
                  <span>About Us</span>
                </a>
              </li>
              <li>
                <a href="/services">
                  {/* <i className="fa fa-angle-right"></i> */}
                  <span>Services</span>
                </a>
              </li>
              <li>
                <a href="/blogs">
                  {/* <i className="fa fa-angle-right"></i> */}
                  <span>Blog</span>
                </a>
              </li>
              <li>
                <a href="/connect-us">
                  {/* <i className="fa fa-angle-right"></i> */}
                  <span>Contact Us</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-6">
            <h5>Links </h5>
            <ul className="social footer-nav">
                  <li>
                    <a href="https://twitter.com/allwin_techX" target="blank" >
                      <BiLogoTwitter style={{fontSize:"30px",color:"#1da1f2"}} id="media"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/people/AllWin-Technologies/61550779260665/" target="blank" >
                      <BiLogoFacebook style={{fontSize:"30px",color:"#3b5998"}}id="media"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/allwintechnologies/" target="blank">
                      <BiLogoLinkedin style={{fontSize:"30px",color:" #0a66c2"}}id="media"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/allwin_technologies/" target="blank" >
                      <BiLogoInstagramAlt style={{fontSize:"30px",color:"#d62976"}}id="media"/>
                    </a>
                  </li>
                </ul>

          </div>
        
          <div className="col-lg-3 col-md-4 col-sm-12">
            <h5>Contact Us</h5>
            <div className="address">
              <p>
                Saraswati Kunj, Sector 53,
                <br />
                Golf Course Road, Gurugram, 122002
              </p>
              <p>Phone: <a href="tel:+91 8000495359">+91 8000495359</a></p>
              <p>
                <span>E-Mail: </span>
                <a href="mailto:Allwintech23@gmail.com">
                  allwintech23@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p className="copyright">
              © 2023 AllWin Technologies. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
